/* Uncomment when optimizing JS
    // enable cookies function
    function enableCookies() {
        // add google analytics script if it has not been added before
        if (gaScriptAdded == false) {
            var googleAnalytics = document.createElement("script");
            googleAnalytics.innerHTML = "(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');ga('create', '" + gaTrackingID + "', 'auto');ga('send', 'pageview');";
            document.head.appendChild(googleAnalytics);
        } else {
            location.reload();
        }
        gaScriptAdded = true;

        // uncomment below for testing
        //document.cookie = "mansCookie=Mans Cookie";
        //var x = document.cookie;
        //console.log('cookies after enabling: '+x);
    }
    // delete cookies function
    window.cookieconsent.Popup.prototype.deleteCookies = function () {
        // list of essential cookies - set as an empty array to delete everything - i.e. var essential = [];
        var essential = ["cookieconsent_status"];

        // list of cookies with specific domain name that should be specified for deletion
        var cookiesWithDomain = ["_ga", "_gid", "_gat"];
        var theDomain = ".acar.lv";

        // uncomment below for testing
        //var x = document.cookie;
        //console.log('cookies before delete: '+x);

        // create array of cookies set
        var cookies = document.cookie.split(";");

        // loop through the cookies
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];

            // remove spaces at the beginning of cookie name
            while (cookie.charAt(0) == ' ') {
                cookie = cookie.substring(1, cookie.length);
            }
            // uncomment below for testing
            //console.log('cookie:'+cookie);

            // get the cookie name
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

            // delete all cookies except those listed in essential
            if (essential === undefined || essential.length == 0 || essential.indexOf(name) == -1) {
                // note assuming path is always = '/'

                // set specific domain for previously defined cookies in "cookiesWithDomain". Do not set domain for others
                if (cookiesWithDomain === undefined || cookiesWithDomain.length == 0 || cookiesWithDomain.indexOf(name) == -1) {
                    document.cookie = name + "=;  path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;";
                } else {
                    document.cookie = name + "=;  path=/; domain=" + theDomain + "; expires=Thu, 01 Jan 1970 00:00:00 GMT;";
                }

                // uncomment below for testing
                //console.log('deleting cookie:'+name);
            }
        }

        // uncomment below for testing
        //var x = document.cookie;
        //console.log('cookies after delete: '+x);
    };

    window.addEventListener("load", function () {
        window.cookieconsent.initialise({
            "position": "bottom-right",
            "type": "opt-in",
            "revokable": true,
            content: {
                message: messageText,
                allow: allowText,
                deny: denyText,
                link: linkText,
                href: hrefToPrivacyPolicy,
            },

            onInitialise: function (status) {
                var type = this.options.type;
                var didConsent = this.hasConsented();
                var x = document.cookie;
                //console.log('cookies on init: '+x);
                if (type == 'opt-in' && didConsent) {
                    // uncomment below for testing
                    //console.log('add cookies on initialise');
                    enableCookies();
                }
            },

            onStatusChange: function (status, chosenBefore) {
                var type = this.options.type;
                var didConsent = this.hasConsented();
                if (type == 'opt-in' && didConsent) {
                    // uncomment below for testing
                    //console.log('add cookies on status change');
                    enableCookies();
                }
            },

            onRevokeChoice: function () {
                var type = this.options.type;
                if (type == 'opt-in') {
                    // uncomment below for testing
                    //console.log('revoke choice');
                    this.deleteCookies();
                }
            }
        });
    });
*/
var $tablet_width = 1199;
var $mobile_width = 767;
var $xs_width = 479;

$(document).ready(function () {
    'use strict';

    var $window = $(window);
    var $windowWidthResize = $window.outerWidth();
    var $document = $(document);
    var $html = $('html');
    var $body = $('body');
    var $siteContent = $('.site-content');


    // Trigger revoke cookies functionality
    $body.on('mouseup', 'a[href="#revoke-cookies"]', function () {
        $('.cc-revoke').trigger('click');
        return false;
    });

    // Script for deprecated browser notification
    $('.close_announcement').click(function (e) {
        e.preventDefault();
        $('.update_browser_fake_body').css('display', 'none');
        $('#browser-notification-style').remove();
    });

    // Replace all .svg to .png, in case the browser does not support the format
    if (!Modernizr.svg) {
        $('img[src*="svg"]').attr('src', function () {
            return $(this).attr('src').replace('.svg', '.png');
        });
        $('*[style*="svg"]').attr('style', function () {
            return $(this).attr('style').replace('.svg', '.png');
        });
    }

    // Padding for homepage search section
    function searchSectionIconPadding() {
        if( $('.catalog-search-wrap .icon-block-wrap').length > 0 ) {
            var $iconBlockWrap = $('.catalog-search-wrap .icon-block-wrap');
            var $iconBlock1st2nd = $iconBlockWrap.find('.icon-block:nth-child(1), .icon-block:nth-child(2)');
            var $iconBlock1st2ndInner = $iconBlockWrap.find('.icon-block:nth-child(1)>.inner, .icon-block:nth-child(2)>.inner');

            $iconBlock1st2nd.css('padding-right', '');
            $iconBlockWrap.find('.icon-block>.inner').css({
                'width': '',
            });

                if ( $window.outerWidth() > $xs_width ) {
                    var availableWidth = parseFloat($iconBlockWrap.width());

                    var $block1 = $iconBlockWrap.find('.icon-block:nth-child(1)>.inner');
                    var $block2 = $iconBlockWrap.find('.icon-block:nth-child(2)>.inner');
                    var $block3 = $iconBlockWrap.find('.icon-block:nth-child(3)>.inner');

                    var $blockWidth1 = parseFloat($block1.outerWidth());
                    var $blockWidth2 = parseFloat($block2.outerWidth());
                    var $blockWidth3 = parseFloat($block3.outerWidth());
                    var $totalBlockWidth = parseFloat($blockWidth1 + $blockWidth2 + $blockWidth3);

                    var $existingPaddingLeft = parseFloat($iconBlockWrap.find('.icon-block:nth-child(3)').css('padding-left')) * 2;
                    var $blockPadding = Math.floor(parseFloat(availableWidth - $totalBlockWidth - $existingPaddingLeft) / 2);
                    var $minPaddingRight = parseFloat($iconBlockWrap.find('.icon-block:nth-child(1)').css('padding-right'));

                    var blockWidthRatio1 = parseFloat($blockWidth1 / $totalBlockWidth);
                    var blockWidthRatio2 = parseFloat($blockWidth2 / $totalBlockWidth);
                    var blockWidthRatio3 = parseFloat($blockWidth3 / $totalBlockWidth);

                    if($minPaddingRight > $blockPadding) {
                        $iconBlock1st2nd.css('padding-right', $minPaddingRight);

                        var availableWidth = parseFloat(availableWidth - $minPaddingRight * 2 - $existingPaddingLeft - 1);
                        $block1.css('width', availableWidth * blockWidthRatio1);
                        $block2.css('width', availableWidth * blockWidthRatio2);
                        $block3.css('width', availableWidth * blockWidthRatio3);
                    } else {
                        $iconBlock1st2nd.css('padding-right', $blockPadding);

                        var availableWidth = parseFloat(availableWidth - $blockPadding * 2 - $existingPaddingLeft - 1);
                        $block1.css('width', availableWidth * blockWidthRatio1 + 0.1);
                        $block2.css('width', availableWidth * blockWidthRatio2 + 0.1);
                        $block3.css('width', availableWidth * blockWidthRatio3 + 0.1);
                    }

                    matchHeights();
                }
        }
    }
    searchSectionIconPadding();

    window.onload = function() {
        //add class to body element after page has loaded (including pictures)

        searchSectionIconPadding();
    }

    $window.resize(function () {
        if( !($windowWidthResize == $window.outerWidth()) ) {
            searchSectionIconPadding();
        }
    });

    // Blur inputs on 'enter'
    $('input, textarea').on('keypress', function(e) {
      if(e.keyCode == 13 && $window.outerWidth() <= $mobile_width && !$(this).hasClass('js-chat-message')) {
        $(this).blur();
        ////console.log('trigger blur');
      }
    });

    // Wrap button text in additional elements for correct positioning
    function btn() {
        $('.btn').each(function () {
            var $this = $(this);
            if (!$this.hasClass('js-initialized')) {
                $this.wrapInner('<div class="btn-inner-cell"></div>');
                $this.wrapInner('<div class="btn-inner-table"></div>');
                $this.addClass('js-initialized');
            }
        });
    }

    btn();

    // Check if button wrapper has 2 buttons
    function btnWrapper2() {
        if ($('.btn-wrapper').length > 0) {
            $('.btn-wrapper').each(function () {
                var $this = $(this);
                if ($this.find('.btn').length == 2) {
                    $this.addClass('btns-inside-2')
                }
            });
        }
    }

    btnWrapper2();

    //print the page
    $('.action-print').click(function() {
      window.print();
      return false;
    });

    // Script for phone inputs
    function phoneInputs() {
        if ($('.js-phone').length > 0) {
            $('.js-phone').each(function () {
                var $this = $(this);
                if (!$this.hasClass('js-initialized')) {
                    ////console.log('tel input initialized');
                    $this.intlTelInput({
                        initialCountry: 'lv'
                    });
                    $this.addClass('js-initialized');
                }
            });
        }
    }

    phoneInputs();

    var verifyCallback = function(response) {
        $('#g-recaptcha-response').val(response);
    };

    function createRecaptcha() {
      if ( $('#captchahere').length ) 
      {
        grecaptcha.render('captchahere', {
            'sitekey' : '6LfBkXwUAAAAAMLL56ud51hO_b_oMJ8bNYvvPmtj',
            'callback' : verifyCallback
        });
      }
    }

    // Script for popups
    function popup() {
        if ($('.js-open-popup').length > 0) {
            $('.js-open-popup').each(function () {
                var $this = $(this);

                if ($(this).hasClass('js-popup-with-close-btn')) {
                    $('.js-open-popup').lightcase({
                        overlayOpacity: 0.6,
                        onBeforeShow: {
                            quux: function () {
                                ////console.log('before show');
                                $html.addClass('js-popup-open');
                                var $lightcaseCase = $('#lightcase-case');
                                if ($(this).attr('data-popup-class')) {
                                    var $popupClass = $(this).data('popup-class');
                                    $lightcaseCase.addClass($popupClass + 'visually-shown');
                                }
                                matchHeights();
                                $lightcaseCase.find('.popup').prepend('<span class="icon icon-close js-popup-close"></span>');
                                $lightcaseCase.find('select').removeClass('js-nojs').addClass('js-select');
                                $lightcaseCase.find('input[type="tel"]').addClass('js-phone');
                                selectPlugin();
                                phoneInputs();
                                disableBodyScrollWOScrollbar();
                            }
                        },
                        onFinish: {
                            corge: function () {
                                var $lightcaseCase = $('#lightcase-case');
                                $lightcaseCase.removeClass('visually-shown');

                                setTimeout(function() {
                                    createRecaptcha();
                                }, 100);

                            }
                        },
                        onCleanup: {
                            garply: function () {
                                ////console.log('on cleanup');
                                enableBodyScroll();
                                $html.removeClass('js-popup-open');
                            }
                        }
                    });
                } else {
                    $('.js-open-popup').lightcase({
                        overlayOpacity: 0.6,
                        onBeforeShow: {
                            quux: function () {
                                ////console.log('before show');
                                $html.addClass('js-popup-open');
                                var $lightcaseCase = $('#lightcase-case');
                                if ($(this).attr('data-popup-class')) {
                                    var $popupClass = $(this).data('popup-class');
                                    $lightcaseCase.addClass($popupClass + 'visually-shown');
                                }
                                matchHeights();
                            }
                        },
                        onFinish: {
                            corge: function () {
                                var $lightcaseCase = $('#lightcase-case');
                                $lightcaseCase.removeClass('visually-shown');

                                setTimeout(function() {
                                    createRecaptcha();
                                }, 100);

                            }
                        },
                        onCleanup: {
                            garply: function () {
                                ////console.log('on cleanup');
                                enableBodyScroll();
                                $html.removeClass('js-popup-open');
                            }
                        }
                    });
                }
            });
        }
    }

    popup();

    // Close popup
    $document.on('mouseup', '#lightcase-content', function (e) {
        var container_1 = $('.popup');
        if (
            !container_1.is(e.target) // if the target of the click isn't the container...
            && container_1.has(e.target).length === 0 // ... nor a descendant of the container
        ) {
            // Close lightcase
            lightcase.close();
        }
    });
    $body.on('click', '.js-popup-close', function () {
        lightcase.close();
    });

    // Script for custom select elements
    function positionOptWrapper() {
        if ($('.SumoSelect.open').length > 0) {
            var $optWrapper = $('.SumoSelect.open').find('.optWrapper');
            var $windowWidth = parseFloat($window.outerWidth());
            $optWrapper.css('margin-left', '');
            var $optWrapperRightPosition = parseFloat($optWrapper.offset().left + $optWrapper.outerWidth());
            if ($windowWidth < $optWrapperRightPosition) {
                $optWrapper.css('margin-left', parseFloat($windowWidth - $optWrapperRightPosition));
            }
        }
    }

    $window.resize(function () {
        if( !($windowWidthResize == $window.outerWidth()) ) {
            positionOptWrapper();
        }
    });

    function adjustSiteContentHeight() {
        $siteContent.css('min-height', '');
        var $siteContentHeight = $siteContent.outerHeight();
        var $siteContentB = parseInt($siteContentHeight - parseInt($siteContent.css('padding-bottom')));
        var $openOptWrapper = $('.SumoSelect.open .optWrapper');
        var $openOptWrapperB = parseInt($openOptWrapper.offset().top + $openOptWrapper.outerHeight());
        if ($siteContentB < $openOptWrapperB) {
            var $setHeight = $siteContentHeight + $openOptWrapperB - $siteContentB;
            $siteContent.css('min-height', $setHeight);
        }
        ////console.log('site content height: '+$siteContentHeight);
        ////console.log('opt wrapper bottom: '+$openOptWrapperB);
        ////console.log('site content bottom: '+$siteContentB);
    }

    function selectPlugin() {
        ////console.log('select plugin go');
        // get texts for select 'ok' and 'cancel' buttons
        if ($('#select-texts').length > 0) {
            var $target = $('#select-texts');
            var $ok = $target.find('#ok-text').html();
            var $cancel = $target.find('#cancel-text').html();
            var $selected_text = $target.find('#selected-text').html();
        } else {
            var $ok = 'ok';
            var $cancel = 'cancel';
            var $selected_text = 'selected';
        }
        // if select exists, initialize plugin
        if ($('.js-select, select').length > 0) {
            $('.js-select, select').each(function () {
                var $this = $(this);
                // if select element has not been initialized before
                if (!$this.hasClass('js-initialized') && !$this.hasClass('js-nojs')) {
                    ////console.log('select element initialized');
                    // if you don't want search function to work
                    if ($this.hasClass('js-disable-search')) {
                        $this.SumoSelect({
                            forceCustomRendering: true,
                            okCancelInMulti: true,
                            floatWidth: 0,
                            captionFormat:'{0} ' + $selected_text,
                            locale: [$ok, $cancel, 'Select All']
                        });
                    } /* if you want search function to work */ else {
                        $this.SumoSelect({
                            //search: true, // uncomment if you want search to work
                            //searchText: '', // uncomment if you want search to work
                            //noMatch: '', // uncomment if you want search to work
                            forceCustomRendering: true,
                            okCancelInMulti: true,
                            floatWidth: 0,
                            captionFormat:'{0} ' + $selected_text,
                            locale: [$ok, $cancel, 'Select All']
                        });
                    }
                    // if element has 'multiple' attribute
                    var $multiple = $this.attr('multiple');
                    if (typeof $multiple !== typeof undefined && $multiple !== false) {
                        var $multiControls = $this.closest('.SumoSelect').find('.MultiControls');
                        var btnOk = $multiControls.find('.btnOk');
                        $multiControls.append(btnOk);
                    }
                    // if element exists in .mobile-1st-block
                    if ($this.closest('.mobile-1st-block').length > 0) {
                        var $optWrapper = $this.closest('.SumoSelect').find('.optWrapper');
                        $optWrapper.addClass('mobile-2nd-wrap');
                        $optWrapper.find('.options').addClass('inner');
                        $optWrapper.find('.opt').addClass('mobile-2nd-block')
                    }
                    // add 'js-initialized' class to elements after they have been initialized
                    $this.addClass('js-initialized');
                }
            });

            $('body').on('change', '.js-submit-on-change', function() {
                $(this).closest( 'form' ).submit();
            });

            // functions to execute on select box opening
            if ($('.js-hide-on-select-opening').length > 0) {
                $('.js-select, select').on('sumo:opening', function (sumo) {
                		var $target = $('.js-hide-on-select-opening');
                    $('.js-hide-on-select-opening').removeClass('visually-shown').addClass('visually-hidden');
                    $target.siblings('.js-open').removeClass('js-open');
                });
            }
            // functions to execute after select box opens
            $('.js-select, select').on('sumo:opened', function (sumo) {
                var $this = $(this).parent();
                var $options = $this.find('.options');
                positionOptWrapper();
                if ($(this).hasClass('js-select-sell-list')) {
                    if ($options.innerHeight() >= 245) { // 5 items = 5*(41+8)-8
                        $options.css('height', 250);
                        $options.niceScroll({
                            cursorwidth: '4px',
                            horizrailenabled: false,
                            cursorcolor: '#a2a5ac',
                            cursorborder: 0,
                            cursorborderradius: 0,
                            zindex: 9999,
                            autohidemode: false,
                            background: '#e3e4e6'
                        });
                    }
                } else {
                    if ($options.innerHeight() >= 200) { // 4 items = 4*(41+8)-8
                        $options.css('height', 196);
                        $options.niceScroll({
                            cursorwidth: '4px',
                            horizrailenabled: false,
                            cursorcolor: '#a2a5ac',
                            cursorborder: 0,
                            cursorborderradius: 0,
                            zindex: 9999,
                            autohidemode: false,
                            background: '#e3e4e6'
                        });
                    }
                }
                adjustSiteContentHeight();
                // Add classes to html element and disable scroll for mobile devices
                /*if($window.outerWidth() <= $xs_width) {
                 disableBodyScroll();
                 $html.addClass('overlay');
                 }*/
            });
            // functions to execute after select has closed
            $('.js-select, select').not('.js-submit-on-change').on('sumo:closed', function (sumo) {
                var $this = $(this).parent();
                var $options = $this.find('.options');
                $this.find('.optWrapper').css('margin-left', '');
                $siteContent.css('min-height', '');
                if ($(this).hasClass('js-select-sell-list')) {
                    var $title = $options.find('.opt:first-child label').html();
                    var $captionCont = $this.find('.CaptionCont');
                    $captionCont.find('span').html($title);
                    $captionCont.attr('title', $title);
                }
                if (typeof $options.getNiceScroll !== "undefined") {
                    $options.css('height', '');
                    $options.getNiceScroll().remove();
                }

                // Remove classes from html element and enable scroll for mobile devices
                /*if($window.outerWidth() <= $xs_width) {
                 enableBodyScroll();
                 $html.removeClass('overlay');
                 }*/
            });
            // Deselect all options on 'cancel' button click
            $body.on('click', '.SumoSelect .MultiControls .btnCancel', function () {
                var $this = $(this);
                $this.closest('.SumoSelect').find('select')[0].sumo.unSelectAll();
                // and close mobile-2nd-wrap
                if ($this.closest('.mobile-block-wrap').length > 0 && $window.outerWidth() <= $mobile_width) {
                    closeMobile2nd();
                }
            });
            // Close mobile-2nd-wrap
            $body.on('click', '.SumoSelect .MultiControls .btnOk', function () {
                if ($(this).closest('.mobile-block-wrap').length > 0 && $window.outerWidth() <= $mobile_width) {
                    closeMobile2nd();
                }
            });
            // Add arrow down for select element in .main-filter-block-sm-down block
            if ($('.main-filter-block-sm-down').children('.SumoSelect').length > 0) {
                ////console.log('add arrow to select');
                var $target = $('.main-filter-block-sm-down').children('.SumoSelect').find('.CaptionCont');
                $target.addClass('flex-y-c').append('<div class="icon icon-arrow-down hide-md-up"></div>');
            }
            disableSelectSearch();
        }
    }
    selectPlugin();

    // Disable search inputs inside select elements on mobile devices
    function disableSelectSearch() {
    	if( $('.js-select, select').parent().find('.search-txt').length > 0 ) {
        if( $window.outerWidth() <= $mobile_width ) {
            $('.js-select, select').each(function() {
            	var $this = $(this);
            	var $this_search = $this.parent().find('.search-txt');
            	if(  $this_search.length > 0 ) {
                $this_search[0].setAttribute("disabled", "");
            	}
            });
        } else {
            var attr = $('.js-select, select').parent().find('.search-txt').attr('disabled');
            if (typeof attr !== typeof undefined && attr !== false) {
                $('.js-select, select').each(function() {
		            	var $this = $(this);
		            	var $this_search = $this.parent().find('.search-txt');
		            	if(  $this_search.length > 0 ) {
		                $this_search[0].removeAttribute("disabled");
		            	}
                });
            }
        }
      }
    }

    $window.resize(function() {
        if( !($windowWidthResize == $window.outerWidth()) ) {
            disableSelectSearch();
        }
    });

    // Range slider
    function initRangeSliders() {

        $('.range-slider-wrap').each(function () {
            // render slider with values from data-attributes
            var $this = $(this);
            var $rangeSlider = $this.find('.range-slider')[0];
            var $min = parseFloat($rangeSlider.getAttribute('data-min'));
            var $max = parseFloat($rangeSlider.getAttribute('data-max'));
            var step = $this.find('.range-slider').data('step');
            var format = $this.find('.range-slider').data('format');
            var $input1Value = parseFloat($rangeSlider.getAttribute('data-i-1')).toFixed(format);
            var $input2Value = parseFloat($rangeSlider.getAttribute('data-i-2')).toFixed(format);

            if (!$rangeSlider.noUiSlider) {
                noUiSlider.create($rangeSlider, {
                    start: [$input1Value, $input2Value],
                    connect: true,
                    step: step,
                    range: {
                        min: $min,
                        max: $max
                    }
                }, true)
            }

            // add values to corresponding inputs from range-slider data attributes
            var $input1ft = $this.find(".i-1");
            var $input2ft = $this.find(".i-2");
            var $input1 = $this.find('.i-1')[0];
            var $input2 = $this.find('.i-2')[0];
            $input1.setAttribute('min', $min);
            $input2.setAttribute('min', $min);
            $input1.setAttribute('max', $max);
            $input2.setAttribute('max', $max);
            $input1.setAttribute('value', $input1Value);
            $input2.setAttribute('value', $input2Value);
            $input1.setAttribute('step', step);
            $input2.setAttribute('step', step);

            // update input value, when corresponding range slider handle value has been changed
            $rangeSlider.noUiSlider.on('update', function (values, handle) {
                var value = values[handle];
                if (handle) {
                    //$input2.value = Math.round(value)
                    $input2.value = parseFloat(value).toFixed(format)
                } else {
                    // $input1.value = Math.round(value)
                    $input1.value = parseFloat(value).toFixed(format)
                }
            });
            $rangeSlider.noUiSlider.on('change', function (values, handle) {
                var value = values[handle];
                if (handle) {
                    $input2ft.trigger('onblur')
                } else {
                    $input1ft.trigger('onblur')
                }
            })
        });

        $('.i-1').on('blur', function () {
            var $rangeSlider = $(this).parents('.range-slider-wrap').find('.range-slider')[0];
            $rangeSlider.noUiSlider.set([this.value, null])
        });
        $('.i-2').on('blur', function () {
            var $rangeSlider = $(this).parents('.range-slider-wrap').find('.range-slider')[0];
            $rangeSlider.noUiSlider.set([null, this.value])
        });
        $('.i-1').on('keyup', function (e) {
            if (e.keyCode == 13) {
                var $rangeSlider = $(this).parents('.range-slider-wrap').find('.range-slider')[0];
                $rangeSlider.noUiSlider.set([this.value, null])
            }
        });
        $('.i-2').on('keyup', function (e) {
            if (e.keyCode == 13) {
                var $rangeSlider = $(this).parents('.range-slider-wrap').find('.range-slider')[0];
                $rangeSlider.noUiSlider.set([null, this.value])
            }
        });

        // set handlers to initial state
        $body.on('click', '.js-range-cancel', function (event) {
            var $rangeSliderWrap = $(this).closest('.range-slider-wrap');
            var $rangeSlider = $rangeSliderWrap.find('.range-slider')[0];
            var format = $rangeSliderWrap.find('.range-slider').data('format');
            var $input1Value = parseFloat($rangeSlider.getAttribute('data-i-1')).toFixed(format);
            var $input2Value = parseFloat($rangeSlider.getAttribute('data-i-2')).toFixed(format);
            var $input1ft = $rangeSliderWrap.find('.i-1');
            var $input2ft = $rangeSliderWrap.find('.i-2');
            var $input1 = $rangeSliderWrap.find('.i-1')[0];
            var $input2 = $rangeSliderWrap.find('.i-2')[0];

            $input1.value = $input1Value;
            $rangeSlider.noUiSlider.set([$input1.value, null]);
            $input2.value = $input2Value;
            $rangeSlider.noUiSlider.set([null, $input2.value]);

            //added by caanz clear get param
            var url = window.location.href;
            var queryparams = url.split('?')[1];

            //query params is set
            if (typeof queryparams !== 'undefined' && queryparams !== 'all-models=1') {
                var paramToClear = $(this).attr("data-filterName");
                var params = queryparams.split('&');
                var pair = null;

                params.forEach(function (d, index) {
                    pair = d.split('=');
                    //clear price
                    if (paramToClear === 'price') {
                        if ( (pair[0] === 'price_from') || ( pair[0] === 'price_to') ) {
                            delete params[index]; // delete element
                        }
                    }
                    else{
                        //clear other custom filters:
                        var currentFilterFrom = 'filter[' + paramToClear + '][from]';
                        var currentFilterTo = 'filter[' + paramToClear + '][to]';
                        var UrlElement = decodeURI(pair[0]);
                        if ((UrlElement === currentFilterFrom) || ( UrlElement === currentFilterTo)) {
                            delete params[index]; // delete element
                        }
                    }
                });

                var urlWithoutQueryParams = window.location.href.split('?')[0];

                if (params.length > 0) {
                    urlWithoutQueryParams = urlWithoutQueryParams + '?';
                    params.forEach(function (d) {
                        urlWithoutQueryParams = urlWithoutQueryParams + d + '&';
                    });

                }

                window.location.replace(urlWithoutQueryParams);

            }

            event.preventDefault()
        });
    }

    initRangeSliders();

    // Script for full-width row (on tablet and mobile devices)
    function fullWidth() {
        if ($('.js-full-width').length > 0) {
            var $body_width = $body.width();
            var $target = $('.js-full-width');

            $target
                .css({
                    'width': $body_width,
                    'margin-left': parseFloat(-$body_width / 2)
                })
                .addClass('full-width opacity-1');
        }
        if ($('.js-full-width-md-down').length > 0) {
            if ($window.outerWidth() <= $tablet_width) {
                var $body_width = $body.width();
                var $target = $('.js-full-width-md-down');

                $target
                    .css({
                        'width': $body_width,
                        'margin-left': parseFloat(-$body_width / 2)
                    })
                    .addClass('full-width opacity-1');
            } else if ($window.outerWidth() > $tablet_width) {
                var $target = $('.js-full-width-md-down');
                $target
                    .css({
                        'width': '',
                        'margin-left': ''
                    })
                    .removeClass('full-width');
            }
        }
        if ($('.js-full-width-sm-down').length > 0) {
            if ($window.outerWidth() <= $mobile_width) {
                var $body_width = $body.width();
                var $target = $('.js-full-width-sm-down');

                $target
                    .css({
                        'width': $body_width,
                        'margin-left': parseFloat(-$body_width / 2)
                    })
                    .addClass('full-width opacity-1');
            } else if ($window.outerWidth() > $mobile_width) {
                var $target = $('.js-full-width-sm-down');
                $target
                    .css({
                        'width': '',
                        'margin-left': ''
                    })
                    .removeClass('full-width');
            }
        }
    }

    fullWidth();
    $window.resize(function () {
        if( !($windowWidthResize == $window.outerWidth()) ) {
            fullWidth();
        }
    });

    // Reduce margin bottom if element before list in .editor-wrapper ends with ':'
    if ($('.editor-wrapper').length > 0) {
        $('.editor-wrapper').each(function () {
            var $this = $(this);
            if ($this.find('ul').length || $this.find('ol').length) {
                var $ul_ol = $this.find('ul, ol');
                $ul_ol.each(function () {
                    var $this = $(this);
                    var $prev = $this.prev();
                    var $prevHtml = $prev.html();
                    // remove spaces at the end of previous element
                    while ($prevHtml.charAt(parseInt($prevHtml.length - 1)) == ' ') {
                        $prevHtml = $prevHtml.substring(0, parseInt($prevHtml.length - 1));
                    }
                    var last_char = $prevHtml.slice(-1);
                    if (last_char == ':') {
                        $this.addClass('bonded-list');
                        $prev.addClass('bonded-list');
                    }
                });
            }
        });
    }

    // Add CSS class to Site Header when scrollTop position of the document is not 0
    var $lastY = $window.scrollTop();

    function add_not_top() {
        $html.addClass('not--top');
    }

    function remove_not_top() {
        $html.removeClass('not--top');
    }

    var $timeout_add_not_top;
    var $timeout_remove_not_top;

    if ($lastY > 50) {
        add_not_top();
    }

    $window.scroll(function () {

        var $currentY = $window.scrollTop();
        if ($currentY > $lastY) {
            var y = 'down';
        } else if ($currentY < $lastY) {
            var y = 'up';
        }
        $lastY = $currentY;
        if ($document.scrollTop() > 50 && y == 'down') {
            $timeout_add_not_top = setTimeout(add_not_top, 150);
        } else if ($document.scrollTop() <= 100 && y == 'up') {
            $timeout_remove_not_top = setTimeout(remove_not_top, 150);
        }

    });

    // Slide-toggle dropdowns on click
    $body.on('mouseup', '.js-toggle-drop', function () {
        ////console.log('.js-toggle-drop');
        var $this = $(this);

        if($this.hasClass('js-toggle-drop-md-down')) {
            if ($window.outerWidth() > $tablet_width) {
                return false;
            }
        } else {
            if ($window.outerWidth() <= $tablet_width) {
                return false;
            }
        }

        ////console.log('.js-toggle-drop aiziet');
        var $target = $this.parent().find('.js-toggle-drop-target');

        // Close everything and remove 'open classes', except this
        $('.js-toggle-drop, .js-toggle-drop-hover').not(this).removeClass('open');
        $('.js-toggle-drop-target').not($target).stop(true, true).slideUp().removeClass('open');

        // Toggle dropdown and 'open' classes for this
        $this.toggleClass('open');
        $target.stop(true, true).slideToggle().toggleClass('open');
        return false;
    });

    // Slide-toggle dropdowns on hover
    $('.js-toggle-drop-hover').mouseenter(function (e) {
        if ($window.outerWidth() > $tablet_width) {
            var $this = $(this);
            var $target = $this.parent().find('.js-toggle-drop-hover-target');

            // Close everything and remove 'open classes', except this
            $('.js-toggle-drop, .js-toggle-drop-hover').not(this).removeClass('open');
            $('.js-toggle-drop-target').not($target).stop(true, true).slideUp().removeClass('open');

            // Open dropdown and  add 'open' class to this
            $this.addClass('open');
            $target.stop(true, true).slideDown().addClass('open');
        }
        return false;
    });

    $('.js-toggle-drop-hover').mouseleave(function (e) {
        if ($window.outerWidth() > $tablet_width) {
            var $this = $(this);
            var $target = $this.parent().find('.js-toggle-drop-target-hover');

            // Close everything and remove 'open' classes
            $('.js-toggle-drop, .js-toggle-drop-hover').removeClass('open');
            $('.js-toggle-drop-target').stop(true, true).slideUp().removeClass('open');
            $('.js-toggle-drop-hover-target').stop(true, true).css('display', 'none').removeClass('open');
        }
        return false;
    });

    $document.on('mouseup', function (e) {
        if ($('.js-toggle-drop.open, .js-toggle-drop-hover.open').length > 0) {

            var container_1 = $('.js-toggle-drop');
            var container_2 = $('.js-toggle-drop-target');
            var container_3 = $('.js-toggle-drop-hover');
            var container_4 = $('.js-toggle-drop-target-hover');
            if (
                !container_1.is(e.target) // if the target of the click isn't the container...
                && container_1.has(e.target).length === 0 // ... nor a descendant of the container
                && !container_2.is(e.target) // and if the target of the click isn't the container...
                && container_2.has(e.target).length === 0 // ... nor a descendant of the container
                && !container_3.is(e.target) // if the target of the click isn't the container...
                && container_3.has(e.target).length === 0 // ... nor a descendant of the container
                && !container_4.is(e.target) // and if the target of the click isn't the container...
                && container_4.has(e.target).length === 0 // ... nor a descendant of the container
            ) {
                // Close everything and remove 'open' classes
                $('.js-toggle-drop, .js-toggle-drop-hover').removeClass('open');
                $('.js-toggle-drop-target').stop(true, true).slideUp().removeClass('open');
                $('.js-toggle-drop-hover-target').stop(true, true).css('display', 'none').removeClass('open');
            }
        }
    });

    $window.resize(function () {
        if ($window.outerWidth() <= $tablet_width) {
            if( !($windowWidthResize == $window.outerWidth()) ) {
                // Close everything and remove 'open' classes
                $('.js-toggle-drop, .js-toggle-drop-hover').removeClass('open');
                $('.js-toggle-drop-target').stop(true, true).slideUp().removeClass('open');
                $('.js-toggle-drop-hover-target').stop(true, true).css('display', 'none').removeClass('open');
            }
        }
    });

    // Toggle mobile menu
    $('.mobile-menu-icon').click(function () {
        var $this = $(this);

        if ($this.hasClass('open')) {
            var $htmlTop = Math.abs(parseFloat($html.css('top'), 10));
            $html.css('top', '');
            $html.removeClass('mobile-menu-open');
            $this.removeClass('open');
            $window.scrollTop($htmlTop);
            $('.mobile-menu-wrap').stop(true, true).slideUp();
        } else {
            var $scrollTop = $window.scrollTop();
            $html.addClass('mobile-menu-open');
            $this.addClass('open');
            $('.mobile-menu-wrap').stop(true, true).slideDown();
            $html.css('top', -$scrollTop);
        }
        return false;
    });

    $window.resize(function () {
        var $windw_width = $window.outerWidth();
        if ($windw_width >= $tablet_width) {
            if( !($windowWidthResize == $window.outerWidth()) ) {
                $('.mobile-menu-icon').removeClass('open');
                $html.removeClass('mobile-menu-open');
                $('.mobile-menu-wrap').css('display', '');
                var $htmlTop = Math.abs(parseFloat($html.css('top'), 10));
                $window.scrollTop($htmlTop);
            }
        }
    });

    // Reorganize header elements to tablet and mobile friendly layout
    function mobileHeader() {
        var $menuCont = $('.header-menu-wrap .inner');
        var $headerMenu = $('.header-menu');
        var $takeThis = $headerMenu.find('.js-take-this');
        var $insertAfterThis = $headerMenu.find('.js-insert-after-this');
        var $headerBtnWrap = $menuCont.find('.header-btn-wrap');
        var $profileWrap = $menuCont.find('.profile-wrap');
        var $chatWrap = $menuCont.find('.chat-wrap');
        var $langSwitcherWrap = $menuCont.find('.lang-switcher-wrap');
        var $footerMenu = $('.footer-menu');
        var $footerMenuItems = $('.footer-menu-item');

        if ($window.outerWidth() <= $tablet_width && !$('.menu-mobile-version').length > 0) {
            $html.addClass('menu-mobile-version');
            $menuCont.prepend($langSwitcherWrap);
            $menuCont.append($headerBtnWrap);
            $takeThis.insertAfter($insertAfterThis);
            $headerMenu.append($footerMenuItems);
        } else if ($window.outerWidth() > $tablet_width && $('.menu-mobile-version').length > 0) {
            $html.removeClass('menu-mobile-version');
            $menuCont.append($langSwitcherWrap);
            if ($chatWrap.length > 0) {
                $chatWrap.insertAfter($headerBtnWrap);
            }
            $profileWrap.insertAfter($headerBtnWrap);
            $headerMenu.prepend($takeThis);
            $footerMenu.prepend($footerMenuItems);
        }
    }

    mobileHeader();
    $window.resize(function () {
        if( !($windowWidthResize == $window.outerWidth()) ) {
            mobileHeader();
        }
    });

    // Move 'mark advertisement' button in mobile devices
    function mobileMarkSell() {
        if ($('.action-mark-sell').length > 0) {
            var $markSellBtn = $('.action-mark-sell');

            if ($window.outerWidth() <= $mobile_width) {
                var $site_content = $('.site-content');
                var $padding = $site_content.css('padding-bottom');

                if (!$('.action-mark-sell-section').length > 0) {
                    $site_content.append($markSellBtn);
                    $markSellBtn.wrap('<a class="action-mark-sell-section btn btn-3 w-100 bg-default text-decor-none" href="javascript:void(0)"></a>');
                    $markSellBtn.addClass('c-on-dark');
                    btn();
                }

                $('.action-mark-sell-section').css({
                    'margin-top': parseFloat($padding),
                    'margin-bottom': -parseFloat($padding)
                });
            } else {
                $markSellBtn.removeClass('c-on-dark');
                $('.sell-view-actions').prepend($markSellBtn);
                $('.action-mark-sell-section').detach();
            }
        }
    }

    mobileMarkSell();
    $window.resize(function () {
        if( !($windowWidthResize == $window.outerWidth()) ) {
            mobileMarkSell();
        }
    });

    // Move leasing button in tablet and mobile devices
    function leasingBtn() {
        if ($('.leasing-btn').length > 0) {

            if ($window.outerWidth() <= $tablet_width && !$('.car-services-map-block .leasing-btn').length > 0) {
                $('.car-services-map-block').append($('.leasing-btn'));
            } else if ($window.outerWidth() > $tablet_width && $('.car-services-map-block .leasing-btn').length > 0) {
                $('.td-leasing-btn').append($('.leasing-btn'));
            }

        }
    }

    leasingBtn();
    $window.resize(function () {
        if( !($windowWidthResize == $window.outerWidth()) ) {
            leasingBtn();
        }
    });

    // Wrap and add full width background to sell view bottom components
    function sellViewBottomWrap() {
        if ($('.js-sell-view-bottom-wrap').length > 0) {
            if ($window.outerWidth() <= $mobile_width && !$('.sell-view-bottom-bg').length > 0) {
                $('.js-sell-view-bottom-wrap').wrapAll('<div class="sell-view-bottom-bg"></div>');
                var $sellViewBottomBg = $('.sell-view-bottom-bg');
                var $section = $sellViewBottomBg.closest('section');
                var $padding = $('.site-content').css('padding-bottom');
                $sellViewBottomBg
                    .insertAfter($section)
                    .css({
                        'padding-bottom': parseFloat($padding),
                        'margin-bottom': -parseFloat($padding)
                    });
            } else if ($window.outerWidth() > $mobile_width && $('.sell-view-bottom-bg').length > 0) {
                $('.js-wrap-insert-back').append($('.sell-view-bottom-bg'));
                $('.js-sell-view-bottom-wrap').unwrap();
            }
        }
    }

    sellViewBottomWrap();
    $window.resize(function () {
        if( !($windowWidthResize == $window.outerWidth()) ) {
            sellViewBottomWrap();
        }
    });

    // Move 'advertisements in map' button in tablet and mobile devices
    function sellInMapBtn() {
        if ($('.sell-in-map-wrap').length > 0) {
            var $target = $('.sell-in-map-wrap');
            if ($window.outerWidth() <= $tablet_width && $('.filter-and-map-wrap .sell-in-map-wrap').length > 0) {
                $target.insertAfter($('.filter-and-map-wrap')).addClass('col col-12');
            } else if ($window.outerWidth() > $tablet_width && !$('.filter-and-map-wrap .sell-in-map-wrap').length > 0) {
                $('.filter-and-map-row.filter-and-map-row-main').append($target);
                $target.removeClass('col col-12');
            }

        }
    }

    sellInMapBtn();
    $window.resize(function () {
        if( !($windowWidthResize == $window.outerWidth()) ) {
            sellInMapBtn();
        }
    });

    // Move other filter next to other filters on mobile devices
    function otherFilters() {
        if ($('.js-other-filter-block').length > 0) {
            var $target = $('.js-other-filter-block');
            if ($window.outerWidth() <= $mobile_width && $('.js-append-other-filter-blocks .js-other-filter-block').length > 0) {
                $('.mobile-filter-wrap.mobile-filter-wrap-main > .inner').append($target);
            } else if ($window.outerWidth() > $mobile_width && $('.mobile-filter-wrap.mobile-filter-wrap-main .js-other-filter-block').length > 0) {
                $('.js-append-other-filter-blocks').append($('.js-other-filter-block'));
            }
        }
    }
    otherFilters();
    $window.resize(function () {
        if( !($windowWidthResize == $window.outerWidth()) ) {
            otherFilters();
        }
    });

    // Move '.sellers-sell-btn' in tablet and mobile devices
    function sellersSellBtn() {
        if ($('.js-sellers-sell-btn').length > 0) {
            var $target = $('.js-sellers-sell-btn');
            if ($window.outerWidth() <= $tablet_width && $('.contact-info ~ .js-sellers-sell-btn').length > 0) {
                $('.contact-info').append($target);
                $target.removeClass('m-t');
            } else if ($window.outerWidth() > $tablet_width && $('.contact-info .js-sellers-sell-btn').length > 0) {
                $target.insertAfter($('.contact-info'));
                $target.addClass('m-t');
            }
        }
        if ($('.js-sellers-sell-btn-sm-down').length > 0) {
            $('.contact-info-wrap').each(function () {
                var $this = $(this);
                var $target = $this.find('.js-sellers-sell-btn-sm-down');
                var $contactInfo = $this.find('.contact-info');
                if ($window.outerWidth() <= $mobile_width && $('.contact-info ~ .js-sellers-sell-btn-sm-down').length > 0) {
                    $contactInfo.append($target);
                    $target.removeClass('m-t');
                } else if ($window.outerWidth() > $mobile_width && $('.contact-info .js-sellers-sell-btn-sm-down').length > 0) {
                    $target.insertAfter($contactInfo);
                    $target.addClass('m-t');
                }
            });
        }
    }

    sellersSellBtn();
    $window.resize(function () {
        if( !($windowWidthResize == $window.outerWidth()) ) {
            sellersSellBtn();
        }
    });

    // Move .js-sort-by-select select element block
    function sortBySelect() {
        if ($('.js-sort-by-select').length > 0) {
            var $target = $('.js-sort-by-select');
            if ($window.outerWidth() <= $mobile_width && $('.mobile-block-wrap .js-sort-by-select').length > 0) {
                $target.insertAfter($('.mobile-block-wrap.mobile-block-wrap-main'));
                ////console.log('move .js-sort-by-select after .mobile-block-wrap');
            } else if ($window.outerWidth() > $mobile_width && $('.mobile-block-wrap .mobile-block-wrap-main ~ .js-sort-by-select').length > 0) {
                $('.mobile-filter-wrap.mobile-filter-wrap-main>.inner').append($target);
                ////console.log('move .js-sort-by-select in the .mobile-filter-wrap');
            }
        }
    }

    sortBySelect();
    $window.resize(function () {
        if( !($windowWidthResize == $window.outerWidth()) ) {
            sortBySelect();
        }
    });

    // Image slider script
    function imageSlider() {
        if ($('.lightslider').length > 0) {
            var slider = 0;

            $(".lightslider").each(function () {
                slider += 1;
                var $this = $(this);
                var $this_slider = $('#lightslider-' + slider);

                if ($this.hasClass("with-pager")) {
                    if ($this.find('li').length < 2) {
                        $this.parent().addClass('slider-one-item');
                        $this.lightSlider({
                            item: 1,
                            pager: false,
                            loop: false,
                            controls: false,
                            enableTouch: false,
                            enableDrag: false,
                            onSliderLoad: function () {
                                $this_slider.removeClass('cS-hidden').addClass('initialized');
                            },
                        });
                    } else {
                        $this.lightSlider({
                            item: 1,
                            //auto      : true,
                            loop: true,
                            pauseOnHover: true,
                            speed: 800,
                            pause: 3000,
                            controls: false,
                            enableTouch: true,
                            enableDrag: true,
                            onSliderLoad: function () {
                                $this_slider.removeClass('cS-hidden').addClass('initialized');
                            },
                        });
                    }
                } else {
                    if ($this.find('li').length < 2) {
                        $this.parent().addClass('slider-one-item');
                        $this.lightSlider({
                            item: 1,
                            pager: false,
                            loop: false,
                            controls: false,
                            enableTouch: false,
                            enableDrag: false,
                            onSliderLoad: function () {
                                $this_slider.removeClass('cS-hidden').addClass('initialized');
                            },
                        });
                    } else {
                        $this.lightSlider({
                            item: 1,
                            pager: false,
                            //auto      : true,
                            loop: true,
                            pauseOnHover: true,
                            speed: 600,
                            pause: 3000,
                            enableTouch: false,
                            enableDrag: false,
                            onSliderLoad: function () {
                                $this_slider.parent().find('.lSAction > a').prepend('<div class="slider-arrow-img"></div>');
                                $this_slider.removeClass('cS-hidden').addClass('initialized');
                            },
                            onAfterSlide: function () {
                                $this_slider.find('video').each(function () {
                                    $(this).get(0).pause();
                                });
                                $this_slider.find('.btn-play').removeClass('opacity-0');
                            }
                        });
                    }
                }
            });

        }
    }

    imageSlider();

    // Count children script
    function countChildren() {
        if ($('.js-count-children').length > 0) {
            $('.js-count-children').each(function () {
                var $this = $(this);
                var $countChildren = parseInt($this.children().length);
                $this.addClass('js-children-' + $countChildren);
            });
        }
    }

    countChildren();
    $window.resize(function () {
        if( !($windowWidthResize == $window.outerWidth()) ) {
            countChildren();
        }
    });

    // Play video by clicking button or on video
    $('.js-video-play').click(function () {
        var $this = $(this);
        var $this_video = $this.find('video').get(0);
        var $this_button = $this.find('.btn-play');
        if (!$this_video.paused) {
            $this_video.pause();
            $this_button.removeClass('opacity-0');
        } else {
            $this_video.play();
            $this_button.addClass('opacity-0');
        }
    });
    $('.js-video-play').each(function () {
        var $this = $(this);
        var $this_video = $this.find('video').get(0);
        var $this_button = $this.find('.btn-play');
        $this_video.addEventListener('ended', function () {
            $this_button.removeClass('opacity-0');
        });
    });

    // Disable body scroll, when 'sell-list-section' map is opened
    $body.on('click', '.sell-in-map-btn', function () {
        if (!$(this).hasClass('js-open')) {
            sellListMapDimensions();
            disableBodyScrollWOScrollbar();
            ////console.log('open map');
        } else {
            enableBodyScroll();
            ////console.log('close map');
        }
    });
    $body.on('click', '.sell-list-map-section .map-wrap .btn-close', function () {
        $('.sell-in-map-btn').removeClass('js-open');
        enableBodyScroll();
    });

    // Hide map when 'sell-list-section' clicked outside of it
    $document.on('mouseup', function (e) {
        if ($('.sell-list-map-section').length > 0 && $('.sell-in-map-btn.js-open').length > 0) {
            var $sellListMapSection = $('.sell-list-map-section');
            var container_1 = $sellListMapSection.find('.map');
            var container_2 = $('.sell-in-map-btn');
            var container_3 = $sellListMapSection.find('.btn-close');
            if (
                !container_1.is(e.target) // if the target of the click isn't the container...
                && container_1.has(e.target).length === 0 // ... nor a descendant of the container
                && !container_2.is(e.target) // and if the target of the click isn't the container...
                && container_2.has(e.target).length === 0 // ... nor a descendant of the container
                && !container_3.is(e.target) // and if the target of the click isn't the container...
                && container_3.has(e.target).length === 0 // ... nor a descendant of the container
            ) {
                $sellListMapSection.removeClass('visually-shown').addClass('visually-hidden');
                $('.sell-in-map-btn').removeClass('js-open');
                enableBodyScroll();
                $body.removeClass('sell-in-map-open');
                ////console.log('close map by clicking outside');
            }
        }
    });

    // Toggle visibility
    // On click
    $body.on('click', '.js-toggle-visibility', function () {
        ////console.log('toggle visibility');
        var $this = $(this);
        var $target = $this.data('visibility-target');
        var $targetEl = $('.js-toggle-visibility-target[data-visibility-target="' + $target + '"]');
        // if this is phone popup, detect positioning
        if($this.hasClass('phone')) {
        	$targetEl.removeClass('show-at-bottom');
        	var $wrapHeight = parseInt($targetEl.outerHeight());
        	var $topHeight = parseInt($this.offset().top - $('.site-header').outerHeight() - $window.scrollTop() - 5);
        	////console.log('$wrapHeight = '+$wrapHeight+' $topHeight = '+$topHeight)
        	if( $wrapHeight > $topHeight ) {
        		$targetEl.addClass('show-at-bottom');
        	}
        }

        if($this.hasClass('sell-in-map-btn')) {
            $body.toggleClass('sell-in-map-open');
        }

        $('.js-toggle-visibility.js-open').not($(this)).not('.js-keep-visible').removeClass('js-open');
        $this.toggleClass('js-open');
        $('.js-toggle-visibility-target').not($('.js-toggle-visibility-target[data-visibility-target="' + $target + '"]')).not('.js-keep-visible').removeClass('visually-shown');
        $targetEl.toggleClass('visually-shown');
        $html.toggleClass('invisible-on-click-outside');
        // close select elements if they exist
        if ($('.SumoSelect.open .js-select').length > 0) {
            $('.SumoSelect.open .js-select').each(function () {
                var $this = $(this)[0];
                $this.sumo.hideOpts();
                if ( !$(this).hasClass('js-submit-on-change') ) {
                    $this.sumo.unSelectAll();
                }
            });
        }
        matchHeights();
        return false;
    });

    $body.on('click', '.js-hide-visibility', function () {
        var $this = $(this);
        var $target = $this.data('visibility-target');
        $('.js-toggle-visibility-target[data-visibility-target="' + $target + '"]').removeClass('visually-shown').addClass('visually-hidden');
        if ($('.js-open[data-visibility-target="' + $target + '"]').length > 0) {
            $('.js-open[data-visibility-target="' + $target + '"]').removeClass('js-open');
        }
        // if element is located in .mobile-block-wrap and .range-slider-wrap, close mobile-2nd-wrap on mobile devices
        if ($this.closest('.mobile-block-wrap').length > 0 && $this.closest('.range-slider-wrap').length > 0 && $window.outerWidth() <= $mobile_width) {
            closeMobile2nd();
            ////console.log('close mobile 2nd wrap on js-hide-visibility');
        }
        ////console.log('.js-hide-visibility clicked');
    });

    $document.on('mouseup', function (e) {
        if ($('.js-invisible-on-click-outside').length > 0) {
            var container_1 = $('.js-invisible-on-click-outside');
            var container_2 = $('.js-invisible-on-click-outside-ignore');
            var container_3 = $('.js-toggle-visibility.js-open');
            if (
                !container_1.is(e.target) // if the target of the click isn't the container...
                && container_1.has(e.target).length === 0 // ... nor a descendant of the container
                && !container_2.is(e.target) // and if the target of the click isn't the container...
                && container_2.has(e.target).length === 0 // ... nor a descendant of the container
                && !container_3.is(e.target) // and if the target of the click isn't the container...
                && container_3.has(e.target).length === 0 // ... nor a descendant of the container
            ) {
                // Add 'visibility-hidden' class to elements width 'js-invisible-on-click-outside' class
                $('.js-invisible-on-click-outside').removeClass('visually-shown').addClass('visually-hidden');
                $('.js-invisible-on-click-outside-ignore.js-open').not('.js-keep-visible').removeClass('js-open');
                $('.js-toggle-visibility.js-open').not('.js-keep-visible').removeClass('js-open');
                $html.removeClass('invisible-on-click-outside');
            }
        }
    });
    // On hover
    $body.on('mouseenter', '.js-toggle-visibility-hover', function () {
        var $this = $(this);
        var $target = $this.data('visibility-target');
        $this.addClass('js-open');
        $('.js-toggle-visibility-hover-target[data-visibility-target="' + $target + '"]').addClass('visually-shown');
        // close select elements if they exist
        if ($('.SumoSelect.open .js-select').length > 0) {
            $('.SumoSelect.open .js-select').each(function () {
                var $this = $(this)[0];
                $this.sumo.hideOpts();
                $this.sumo.unSelectAll();
            });
        }
        return false;
    });
    $body.on('mouseleave', '.js-toggle-visibility-hover', function () {
        var $this = $(this);
        var $target = $this.data('visibility-target');
        $this.removeClass('js-open');
        $('.js-toggle-visibility-hover-target[data-visibility-target="' + $target + '"]').removeClass('visually-shown');
        return false;
    });

    // Toggle visibility with radio buttons
    function radioVisibilityToggle($radio) {
        var $data_target = $radio[0].getAttribute('data-target');
        var $data_target_group = $radio[0].getAttribute('data-target-group');

        if ($radio.is(':checked')) {
            var $data_target_exist = $radio.attr('data-target');

            // If radio has 'data-target' attribute, toggle 'data-id' blocks accordingly
            if (typeof $data_target_exist !== typeof undefined && $data_target_exist !== false) {
                if ($data_target == 'none') {
                    $('[data-target-group="' + $data_target_group + '"]').removeClass('active');
                    $('[data-id-group="' + $data_target_group + '"]').removeClass('active');
                } else {
                    $('[data-target-group="' + $data_target_group + '"]').removeClass('active');
                    $radio.addClass('active');
                    $('[data-id-group="' + $data_target_group + '"]').removeClass('active');
                    $('[data-id="' + $data_target + '"]').addClass('active');
                    matchHeights();
                }
            }
        }
    }

    $body.on('change', 'input[type="radio"].js-radio-visibility-toggle', function () {
        radioVisibilityToggle($(this));
    });
    if ($('input[type="radio"].js-radio-visibility-toggle').length > 0) {
        $('input[type="radio"].js-radio-visibility-toggle').each(function () {
            radioVisibilityToggle($(this));
        });
    }

    // Toggle limited height
    $body.on('click', '.js-limited-h-sm-down, .js-limited-h', function () {
        var $target = $(this).data('limited-h-target');
        $('.js-limited-h-target[data-limited-h-target="' + $target + '"]').toggleClass('show-full-height');
        return false;
    });
    function setLimitedHeight() {
        if ($('.js-limited-h').length > 0) {
            $('.js-limited-h').each(function () {
                var $this = $(this);
                var $target = $this.data('limited-h-target');
                var $windowWidth = $window.outerWidth();
                //desktop devices
                if ($windowWidth > $tablet_width) {
                    var attr = $this.attr('data-limited-h-height-lg-up');
                    if (typeof attr !== typeof undefined && attr !== false) {
                        var $maxHeight = parseFloat($this.data('limited-h-height-lg-up'));
                    } else {
                        var $maxHeight = parseFloat($this.data('limited-h-height'));
                    }
                } else if /* tablet devices */ ($windowWidth <= $tablet_width && $windowWidth > $mobile_width) {
                    var attr = $this.attr('data-limited-h-height-md');
                    if (typeof attr !== typeof undefined && attr !== false) {
                        var $maxHeight = parseFloat($this.data('limited-h-height-md'));
                    } else {
                        var $maxHeight = parseFloat($this.data('limited-h-height'));
                    }
                } else /* mobile devices */ {
                    var attr = $this.attr('data-limited-h-height-sm-down');
                    if (typeof attr !== typeof undefined && attr !== false) {
                        var $maxHeight = parseFloat($this.data('limited-h-height-sm-down'));
                    } else {
                        var $maxHeight = parseFloat($this.data('limited-h-height'));
                    }
                }
                var $targetEl = $('.js-limited-h-target[data-limited-h-target="' + $target + '"]');
                var $targetText = $targetEl.find('.limited-h-text');
                $targetText.css('max-height', '');
                if ($targetText.innerHeight() > $maxHeight) {
                    $targetEl.addClass('over-h-limit');
                    $targetText.css('max-height', $maxHeight);
                }
            });
        }
        if ($('.js-limited-h-sm-down').length > 0) {
            if ($window.outerWidth() <= $mobile_width) {
                $('.js-limited-h-sm-down').each(function () {
                    var $this = $(this);
                    var $maxHeight = parseFloat($this.data('limited-h-height'));
                    var $target = $this.data('limited-h-target');
                    var $targetEl = $('.js-limited-h-target[data-limited-h-target="' + $target + '"]');
                    var $targetText = $targetEl.find('.limited-h-text');
                    $targetText.css('max-height', '');
                    if ($targetText.innerHeight() > $maxHeight) {
                        $targetEl.addClass('over-h-limit');
                        $targetText.css('max-height', $maxHeight);
                    }
                });
            } else {
                $('.js-limited-h-sm-down').each(function () {
                    var $target = $(this).data('limited-h-target');
                    $('.js-limited-h-target[data-limited-h-target="' + $target + '"]').find('.limited-h-text').css('max-height', '');
                });
            }
        }
    }

    setLimitedHeight();
    $window.resize(function () {
        if( !($windowWidthResize == $window.outerWidth()) ) {
            setLimitedHeight();
        }
    });

    // Match height with width
    function matchHeightWithWidth() {
        if ($('.js-w-to-h').length > 0) {
            $('.js-w-to-h').each(function () {
                var $this = $(this);
                $this.css('height', parseFloat($this.outerWidth()));
            });
        }
    }

    matchHeightWithWidth();
    $window.resize(function () {
        if( !($windowWidthResize == $window.outerWidth()) ) {
            matchHeightWithWidth();
        }
    });

    // Fixed height
    function fixedHeight() {
        if ($('.js-fixed-h').length > 0) {
        		////console.log('fixedHeight');
            $('.js-fixed-h').css('height', '');
            $('.js-fixed-h').each(function () {
                var $this = $(this);
                $this.css('height', parseFloat($this.outerHeight()));
            });
        }
    }

    // Cancel fixed height
    var dontExecuteCancelFixedHeight = false;
    function cancelFixedHeight() {
        if ($('.js-fixed-h').length > 0 && !dontExecuteCancelFixedHeight) {
        		////console.log('cancelFixedHeight');
            $('.js-fixed-h').css('height', '');
        }
        dontExecuteCancelFixedHeight = false;
    }

    // Contain child element
    function containChild() {
        if ($('.js-contain-child').length > 0) {
            $('.js-contain-child').each(function () {
                var $this = $(this);
                var $children = $this.children();
                $children.css({
                    'max-width': 0,
                    'max-height': 0
                });
                $children.css({
                    'max-width': parseFloat($this.innerWidth()),
                    'max-height': parseFloat($this.innerHeight())
                });
            });
        }
    }

    containChild();
    $window.resize(function () {
        if( !($windowWidthResize == $window.outerWidth()) ) {
            containChild();
        }
    });

    // Fill height with specific section
    function fillHeight() {
        if ($('.js-grow-h-md-down').length > 0) {
            if ($window.outerWidth() <= $tablet_width) {
                if ($('.js-grow-h-item').lenght > 0) {
                    var $height = parseFloat($window.height() - $('.site-header').outerHeight() - $('.site-footer').outerHeight() - $('.js-grow-h-item').outerHeight());
                } else {
                    var $height = parseFloat($window.height() - $('.site-header').outerHeight() - $('.site-footer').outerHeight());
                }
                $('.js-grow-h-md-down').css('min-height', $height);
            } else {
                $('.js-grow-h-md-down').css('min-height', '');
            }
        }
    }

    fillHeight();
    $window.resize(function () {
        if( !($windowWidthResize == $window.outerWidth()) ) {
            fillHeight();
        }
    });

    // Match specific element heights within parent
    function matchHeights() {
      ////console.log('equal heights f-tion');
        if ($('.js-equal-h-wrap-md-up').length > 0) {
            ////console.log('equal heights');
            if ($window.outerWidth() > $mobile_width) {
                $('.js-equal-h-wrap-md-up').each(function () {
                    var $equalHeightBlock = $(this).find('.js-equal-h');
                    $equalHeightBlock.css('height', '');
                    var $heights = $equalHeightBlock.map(function () {
                        return $(this).outerHeight()
                    }).get();
                    var $maxHeight = parseFloat(Math.max.apply(null, $heights));
                    $equalHeightBlock.css('height', $maxHeight);
                });
            } else {
                $('.js-equal-h-wrap-md-up').each(function () {
                    $(this).find('.js-equal-h').css('height', '');
                });
            }
        }
        if ($('.js-equal-h-wrap-sm-up').length > 0) {
            ////console.log('equal heights');
            if ($window.outerWidth() > $xs_width) {
                $('.js-equal-h-wrap-sm-up').each(function () {
                    var $equalHeightBlock = $(this).find('.js-equal-h');
                    $equalHeightBlock.css('height', '');
                    var $heights = $equalHeightBlock.map(function () {
                        return $(this).outerHeight()
                    }).get();
                    var $maxHeight = parseFloat(Math.max.apply(null, $heights));
                    $equalHeightBlock.css('height', $maxHeight);
                });
            } else {
                $('.js-equal-h-wrap-sm-up').each(function () {
                    $(this).find('.js-equal-h').css('height', '');
                });
            }
        }
        if ($('.js-equal-h-wrap').length > 0) {
            ////console.log('equal heights');
            $('.js-equal-h-wrap').each(function () {
                var $equalHeightBlock = $(this).find('.js-equal-h');
                $equalHeightBlock.css('height', '');
                var $heights = $equalHeightBlock.map(function () {
                    return $(this).outerHeight()
                }).get();
                var $maxHeight = parseFloat(Math.max.apply(null, $heights));
                $equalHeightBlock.css('height', $maxHeight);
            });
        }
    }

    matchHeights();
    $window.resize(function () {
        if( !($windowWidthResize == $window.outerWidth()) ) {
            matchHeights();
        }
        ////console.log('trigger matchHeights on window resize');
    });

    // Fill height script
    function fillElementHeight() {
        if ($('.js-fill-el-h').length > 0 && $('.js-fill-el-h-target').length > 0) {
            $('.js-fill-el-h').each(function () {
                var $this = $(this);
                var $target = $this.data('fill-el-h-target');
                var $targetEl = $('.js-fill-el-h-target[data-fill-el-h-target="' + $target + '"]');

                $targetEl.css({
                    'min-height': 0,
                    'height': 0
                });

                var minusHeight = 0;
                if ($('.js-fill-el-h-minus[data-fill-el-h-target="' + $target + '"]').length > 0) {
                    ////console.log('minus el exists');
                    var $minusEl = $('.js-fill-el-h-minus[data-fill-el-h-target="' + $target + '"]');
                    $minusEl.each(function () {
                        minusHeight = parseInt(minusHeight + parseInt($(this).outerHeight()));
                        ////console.log('minus el height: '+minusHeight);
                    });
                }
                ////console.log('minus height: '+minusHeight);

                var $height = $this.height();
                $targetEl.css({
                    'min-height': parseInt($height - minusHeight),
                    'height': ''
                });

                setTimeout(function () {
                    var $heights = $targetEl.map(function () {
                        return $(this).outerHeight()
                    }).get();
                    var $maxHeight = parseFloat(Math.max.apply(null, $heights));
                    $targetEl.css('min-height', $maxHeight);
                }, 0);
            });
        }
        // Chat height
        if ($('.js-chat-body-h').length > 0) {
            var $target = $('.js-chat-body-h');
            $target.css('height', 0);
            var $targetHeight = parseFloat($target.innerHeight());
            var $targetMinHeight = parseFloat($target.css('min-height'));
            var $fillHeights = $('.js-fill-el-h-target').not('.js-chat-body-h').map(function () {
                return $(this).outerHeight()
            }).get();
            var $maxFillHeight = parseFloat(Math.max.apply(null, $fillHeights) - $('.chat-header').outerHeight() - $('.chat-footer').outerHeight());
            ////console.log('max fill height: '+$maxFillHeight);
            var $maxHeight = Math.max($maxFillHeight, $targetMinHeight, $targetHeight);
            ////console.log('max height: '+$maxHeight);

            $target.css('height', $maxHeight);
        }
    }

    fillElementHeight();
    $window.resize(function () {
        if( !($windowWidthResize == $window.outerWidth()) ) {
            fillElementHeight();
        }
    });

    // Disable and enable body scroll
    function disableBodyScroll() {
        var $scrollTop = $window.scrollTop();
        $html.css("top", -$scrollTop);
        $html.addClass('disable-scroll');
        fullWidth();
    }

    function disableBodyScrollWOScrollbar() {
        var $scrollTop = $window.scrollTop();
        $html.css("top", -$scrollTop);
        $html.addClass('disable-scroll-wo-scrollbar');
        fullWidth();
    }

    function enableBodyScroll() {
    	if( $html.hasClass('disable-scroll') || $html.hasClass('disable-scroll-wo-scrollbar') ) {
        var $htmlTop = Math.abs(parseInt($html.css('top'), 10));
        ////console.log('html top: ' + $htmlTop);
        $html.removeClass('disable-scroll disable-scroll-wo-scrollbar');
        $html.css("top", '');
        $window.scrollTop($htmlTop);
        fullWidth();
      }
    }

    function getSecondLevel(categoryId, element, e) {
        var locale = $('meta[name="locale"]').attr('content');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            type: "POST",
            data: {categoryId: categoryId},
            url: '/' + locale + '/tree-get-second-level-categories',
            success: function (data) {
                $('#first-level-' + categoryId).append(data.next_level_categories_block);
                element.trigger( "mouseup" );

                $('#first-level-' + categoryId).find('.series-block').each(function() {
                    if ( $(this).height() > 50 ) {
                        $(this).addClass('small-font');
                    }
                });
            }
        });

    }

    // Catalog mobile 1st wraps
    $('body').on('mouseup', '.js-toggle-mobile-1st-wrap', function (e) {
      e.stopPropagation();
      var $this = $(this);
      var $thisIsOpen = $this.hasClass('js-open');

      	// don't execute this code for desktop devices filter blocks section
      	if($window.outerWidth() > $mobile_width && $this.find('.filter-block').length > 0) {
      		return false;
      	}

        if ( !$this.hasClass( "clickedFirstLevel" ) && $this.hasClass( "load-car-data" )) {
            $this.addClass('clickedFirstLevel');
            getSecondLevel($this.attr("data-category-id"), $this, e);
        } else {
            // If there is an open .js-toggle-mobile-1st-wrap element
            ////console.log('js-toggle-mobile-1st-wrap');
            if ($('.js-toggle-mobile-1st-wrap.js-open').length > 0) {
                // But this click is not on .mobile-1st-wrap
                var container = $this.find('.mobile-1st-wrap');
                if (
                    !container.is(e.target) // if the target of the click isn't the container...
                    && container.has(e.target).length === 0 // ... nor a descendant of the container
                    && ($window.outerWidth() > $mobile_width) // and this is not mobile device
                ) {
                    // Close mobile 1st and 2nd wraps
                    ////console.log('1st close');
                    closeMobile1stAnd2nd();
                }
                else if /* if this element is open */ ( $thisIsOpen ) {
                    // and the click is on .js-toggle-mobile-2nd-wrap
                    var container = $this.find('.js-toggle-mobile-2nd-wrap');
                    if (
                        container.is(e.target) // if the target of the click is the container...
                        || container.has(e.target).length > 0 // ... or a descendant of the container
                    ) {
                        // execute function and stop this function
                        var $eTarget = $(e.target);
                        if( $eTarget.hasClass('.js-toggle-mobile-2nd-wrap') ) {
                            var $clickedTargetEl = $eTarget;
                        } else {
                            var $clickedTargetEl = $eTarget.closest('.js-toggle-mobile-2nd-wrap');
                        }
                        jsToggleMobile2ndWrap($clickedTargetEl, e);
                        return false;
                    }
                }
            }
            // if click target element has .mobile-1st-wrap, but it is not open yet, open it's mobile-1st-wrap
            ////console.log($thisIsOpen);
            if ($this.find('.mobile-1st-wrap').length > 0 && !$thisIsOpen) {
                ////console.log('open this mobile-1st-wrap');
                /* scroll to $this element */
                setTimeout(function() {
                    var $thisTop = Math.abs(parseInt($this.offset().top));
                    var $thisMargin = parseInt($this.css('margin-top')) * 2;
                    var $headerHeight = parseInt($('.site-header').outerHeight());
                    var $scrollToThis = parseInt($thisTop - $headerHeight - $thisMargin);
                    //$window.scrollTop($scrollToThis);
                      $("html, body").animate({
                          scrollTop: $scrollToThis
                      }, 300);
                    }, 150);
                /**/

                if ($window.outerWidth() > $mobile_width) {

                    var $mobile1stWrap = $this.find('.mobile-1st-wrap');

                    // js for catalog blocks
                    if ($this.hasClass('catalog-block')) {
                        var $catalogInnerWrap = $this.closest('.catalog-inner-wrap');
                        var $margins = parseFloat($this.css('margin-left') + $this.css('margin-right'));
                        var $marginBottom = parseFloat($this.css('margin-bottom'));
                        var $width = parseFloat($catalogInnerWrap.outerWidth() - $margins * 2);
                        var $catalogInnerOffset = $catalogInnerWrap.offset();
                        var $thisOffset = $this.offset();
                        var $left = parseFloat($catalogInnerOffset.left - $thisOffset.left);
                        $mobile1stWrap.css({
                            'width': $width,
                            'left': $left
                        });
                        $this.css('margin-bottom', parseFloat($mobile1stWrap.outerHeight() + $marginBottom)).addClass('js-open');
                    }

                    $mobile1stWrap.removeClass('visually-hidden');
                    $html.addClass('mobile-wrap-open');

                } else if ($window.outerWidth() <= $mobile_width) {
                    $this.addClass('js-open');
                    $this.find('.mobile-1st-wrap').toggle('slide', 'right', 150);//.removeClass('visually-hidden')
                    $this.find('.mobile-1st-wrap>.inner').niceScroll({
                        cursorwidth: '4px',
                        horizrailenabled: false,
                        cursorcolor: '#a2a5ac',
                        cursorborder: 0,
                        cursorborderradius: 0,
                        zindex: 9999,
                        autohidemode: false,
                        background: '#e3e4e6'
                    });

                    //console.log('toggle right');
                    disableBodyScrollWOScrollbar();
                    setTimeout(function () {
                        $html.addClass('mobile-wrap-open overlay');
                        $body.append('<div id="body-overlay"><a class="close" href="javascript:void(0)"></a></div>');
                    }, 50);
                }
            }

        }


    });

    // Mobile 2nd wraps
    $('body').on('mouseup', '.js-toggle-mobile-2nd-wrap', function (e) {
        e.stopPropagation();
        jsToggleMobile2ndWrap($(this), e);
    });

    function jsToggleMobile2ndWrap($this, e) {

      	// don't execute this code for desktop devices filter blocks section
      	if($window.outerWidth() > $mobile_width && $this.hasClass('filter-block')) {
      		return false;
      	}

        var $thisIsOpen = $this.hasClass('js-open');

        ////console.log('js-toggle-mobile-2nd-wrap');
        ////console.log('$thisIsOpen = '+$thisIsOpen);

        // If there is an open .js-toggle-mobile-2nd-wrap element
        if ($('.js-toggle-mobile-2nd-wrap.js-open').length > 0) {
            // And the click is not on .mobile-2nd-wrap
            var container = $this.find('.mobile-2nd-wrap');
            if (
                !container.is(e.target) // if the target of the click isn't the container...
                && container.has(e.target).length === 0 // ... nor a descendant of the container
            ) {
                // Close mobile 2nd wraps
              	////console.log('close mobile 2nd by clicking on js-toggle-mobile-2nd-wrap');
                closeMobile2nd();
            }
        }

        // if click target element has .mobile-2nd-wrap, but it is not open yet, open it's mobile-2nd-wrap
        if ($this.find('.mobile-2nd-wrap').length > 0 && !$thisIsOpen) {
            ////console.log('there is mobile-2nd wrap inside and this element is not open yet');
            if ($window.outerWidth() > $mobile_width) {
            		////console.log('open-mobile-2nd-wrap');

                var $mobile2ndWrap = $this.find('.mobile-2nd-wrap');

                // js for series blocks
                if ($this.hasClass('series-block')) {
                    //fixedHeight();
                	$('.js-toggle-mobile-2nd-wrap.js-open').css('margin-bottom', '');
                    dontExecuteCancelFixedHeight = true;
                    var $mobile1stBlockSiblings = $this.parent().children('.js-fixed-h');
		            $mobile1stBlockSiblings.css('height', '');
		            $mobile1stBlockSiblings.each(function () {
		                $(this).css('height', parseFloat($this.outerHeight()));
		            });

                    var $mobile1stWrap = $this.closest('.mobile-1st-wrap');
                    var $toggleMobile1stWrap = $this.closest('.js-toggle-mobile-1st-wrap');
                    var $margins = parseFloat($this.css('margin-left') + $this.css('margin-right'));
                    var $marginBottom = parseFloat($this.find('.mobile-2nd-block').css('margin-bottom'));
                    var $width = parseFloat($mobile1stWrap.outerWidth());
                    var $mobile1stWrapOffset = $mobile1stWrap.offset();
                    var $thisOffset = $this.offset();
                    var $left = parseFloat($mobile1stWrapOffset.left - $thisOffset.left);

                    $mobile2ndWrap.css({
                        'width': $width,
                        'left': $left
                    });

                    var $mobile2ndWrapHeight = $mobile2ndWrap.outerHeight();

                   	setTimeout(function() {
	                    $toggleMobile1stWrap.css('margin-bottom', parseFloat(parseFloat($toggleMobile1stWrap.css('margin-bottom')) + $mobile2ndWrapHeight - $marginBottom)).addClass('js-open-mobile-2nd-inside');
	                    $this.css('margin-bottom', parseFloat($mobile2ndWrapHeight)).addClass('js-open');
                   	}, 100);
                }
                // if element is not inside select
                if (!$mobile2ndWrap.hasClass('optWrapper')) {
                    setTimeout(function() {
                        $mobile2ndWrap.removeClass('visually-hidden');
                    }, 150);
                }
            } else if ($window.outerWidth() <= $mobile_width) {
                ////console.log('this is mobile width');
                var $mobile2ndWrap = $this.find('.mobile-2nd-wrap');
                var $titleText = $this.find('.js-mobile-1st-title').html();
                $mobile2ndWrap.prepend('<a class="mobile-2nd-wrap-header p text-decor-none flex-y-c js-back-to-mobile-1st" href="#"><span class="icon icon-arrow-left"></span>' + $titleText + '</a>');
                $this.addClass('js-open');
                $this.closest('.js-toggle-mobile-1st-wrap').addClass('js-open-mobile-2nd-inside');
                $mobile2ndWrap.toggle('slide', 'right', 150);
                $this.find('.mobile-2nd-wrap>.inner').niceScroll({
                    cursorwidth: '4px',
                    horizrailenabled: false,
                    cursorcolor: '#a2a5ac',
                    cursorborder: 0,
                    cursorborderradius: 0,
                    zindex: 9999,
                    autohidemode: false,
                    background: '#e3e4e6'
                });

                // if element is not inside select
                if (!$mobile2ndWrap.hasClass('optWrapper')) {
                    // .removeClass('visually-hidden')
                    //console.log('toggle right');
                }
            }
        }
    };

    function closeMobile2nd() {

        if ($('.mobile-2nd-wrap').length > 0) {
        		////console.log('closeMobile2nd');

            // Close mobile 2nd wrap
            var $toggleMobile2ndWrap = $('.js-toggle-mobile-2nd-wrap.js-open');
            var $toggleMobile1stWrap = $toggleMobile2ndWrap.closest('.js-toggle-mobile-1st-wrap');
            var $mobile2ndWrap = $toggleMobile2ndWrap.find('.mobile-2nd-wrap');
            var $mobile1stWrap = $toggleMobile2ndWrap.closest('.mobile-1st-wrap');

            if( $window.outerWidth() <= $mobile_width ) {
                $mobile1stWrap.css('display', 'none');
                $mobile1stWrap.children('.inner').getNiceScroll().remove();
                $toggleMobile2ndWrap.find('.mobile-2nd-wrap').css('display', 'none');
            }

            // if element is not inside select
            if (!$mobile2ndWrap.hasClass('optWrapper')) {
        		// add class to all 2nd wraps
                if( $window.outerWidth() > $mobile_width ) {
                    $toggleMobile2ndWrap.find('.mobile-2nd-wrap').addClass('visually-hidden');
                }
                ////console.log('addClass visually-hidden to $mobile2ndWrap');
            }

            setTimeout(function () {
                $mobile2ndWrap.find('.mobile-2nd-wrap-header').detach();

                // remove margin from all js-toggle-mobile-2nd-wrap
                $('.js-toggle-mobile-2nd-wrap').css('margin-bottom', '').removeClass('js-open');
                $toggleMobile1stWrap.removeClass('js-open-mobile-2nd-inside');

                if( $window.outerWidth() > $mobile_width ) {
                    // js for series blocks
                    if ($toggleMobile2ndWrap.hasClass('series-block')) {
                        var $marginBottom = parseFloat($toggleMobile2ndWrap.find('.mobile-2nd-block').css('margin-bottom'));
                        var $mobile2ndWrapHeight = $mobile2ndWrap.outerHeight();
                        $toggleMobile1stWrap.css('margin-bottom', parseFloat(parseFloat($toggleMobile1stWrap.css('margin-bottom')) - $mobile2ndWrapHeight + $marginBottom));
                        $mobile2ndWrap.css({
                            'width': '',
                            'left': ''
                        });
                        if( $windowWidthResize <= $mobile_width ) {
                            $mobile2ndWrap.children('.inner').getNiceScroll().remove();
                        }
                        cancelFixedHeight();
                    }
                } else {
                    $mobile1stWrap.toggle('slide', 'right', 150);
                    $mobile1stWrap.children('.inner').niceScroll({
                        cursorwidth: '4px',
                        horizrailenabled: false,
                        cursorcolor: '#a2a5ac',
                        cursorborder: 0,
                        cursorborderradius: 0,
                        zindex: 9999,
                        autohidemode: false,
                        background: '#e3e4e6'
                    });
                }
            }, 50);

        }

    }

    function closeMobile1stAnd2nd() {

        if ( $('.mobile-2nd-wrap').length == 0 ) {
            $('<div class="fakemob mobile-2nd-wrap"/>').appendTo('body').hide();
        }

        if ($('.mobile-1st-wrap').length > 0 && $('.mobile-2nd-wrap').length > 0) {

            // cancel execution of function if map is open
            if ($('.sell-in-map-btn.js-open').length > 0) {
                return false;
            }

            // Close mobile 2nd wrap
            var $toggleMobile2ndWrap = $('.js-toggle-mobile-2nd-wrap.js-open');
            var $mobile2ndWrap = $toggleMobile2ndWrap.find('.mobile-2nd-wrap');

            // if element is not inside select
            if( $window.outerWidth() <= $mobile_width ) {
                $mobile2ndWrap.css('display', 'none');
                $mobile2ndWrap.children('.inner').getNiceScroll().remove();
            } else {
                $mobile2ndWrap.addClass('visually-hidden');
                if( $windowWidthResize <= $mobile_width ) {
                    $mobile2ndWrap.children('.inner').getNiceScroll().remove();
                }
            }

            setTimeout(function () {
                $mobile2ndWrap.find('.mobile-2nd-wrap-header').detach();
                $toggleMobile2ndWrap.css('margin-bottom', '').removeClass('js-open');
                // js for series blocks
                if ($toggleMobile2ndWrap.hasClass('series-block')) {
                    $mobile2ndWrap.css({
                        'width': '',
                        'left': ''
                    });
                    cancelFixedHeight();
                }
            }, 40);

            // Close mobile 1st wrap
            var $toggleMobile1stWrap = $('.js-toggle-mobile-1st-wrap.js-open');
            var $mobile1stWrap = $toggleMobile1stWrap.find('.mobile-1st-wrap');

            $html.removeClass('mobile-wrap-open overlay');
            $('#body-overlay').detach();
            $('.fakemob').remove();
            enableBodyScroll();
            if( $window.outerWidth() <= $mobile_width ) {
                $mobile1stWrap.css('display', 'none');
                $mobile1stWrap.children('.inner').getNiceScroll().remove();
            } else {
                $mobile1stWrap.addClass('visually-hidden');
                if( $windowWidthResize <= $mobile_width ) {
                    $mobile1stWrap.children('.inner').getNiceScroll().remove();
                }
            }
            setTimeout(function () {
                // js for catalog blocks
                if ($toggleMobile1stWrap.hasClass('catalog-block')) {
                    $toggleMobile1stWrap.css('margin-bottom', '');
                    $mobile1stWrap.css({
                        'width': '',
                        'left': ''
                    });
                }
                $toggleMobile1stWrap.removeClass('js-open').removeClass('js-open-mobile-2nd-inside');
            }, 40);

        }
    }

    // If the click is outside of .js-toggle-mobile-1st-wrap and all their child elements
    $document.on('click', 'body', function (e) {
        if ($('.js-toggle-mobile-1st-wrap.js-open').length > 0) {
            var container = $('.js-toggle-mobile-1st-wrap');
            var container_2 = $('.js-back-to-mobile-1st');
            if (
                !container.is(e.target) // if the target of the click isn't the container...
                && container.has(e.target).length === 0 // ... nor a descendant of the container
                && !container_2.is(e.target) // if the target of the click isn't the container...
                && container_2.has(e.target).length === 0 // ... nor a descendant of the container
            ) {
                ////console.log('2nd close');
                closeMobile1stAnd2nd();
                ////console.log('click on body for closing mobile wraps. 2nd close');
            }
        }
    });

    $window.resize(function () {
        if ($window.outerWidth() > $mobile_width && $windowWidthResize <= $mobile_width) {
            ////console.log('3rd close before');
            if( !($windowWidthResize == $window.outerWidth()) ) {
                //console.log('3rd close real');
                closeMobile1stAnd2nd();

                $('.mobile-1st-wrap, .mobile-2nd-wrap').css('display', '');
                $('.mobile-1st-wrap>.inner, .mobile-2nd-wrap>.inner').getNiceScroll().remove()
                $('.mobile-1st-wrap>.inner, .mobile-2nd-wrap>.inner').css({
                	'overflow-y': '',
                	'outline': '',
                	'cursor': '',
                	'touch-action': '',
                });

            }
        }
    });

    // Update nicescroll on mobile wrap dimention change ( for ios, when browser top bar is sliding in and out. Window height is not changing, but container height - is changing )
    if ($('.mobile-1st-wrap>.inner').length > 0 || $('.mobile-2nd-wrap>.inner').length > 0) {
    	var element = $('.mobile-1st-wrap>.inner, .mobile-2nd-wrap>.inner');

			new ResizeSensor(element, function() {
				if( $window.outerWidth() <= $mobile_width ) {
					element.getNiceScroll().resize()
				}
			});
    }

    $body.on('mouseup', '.js-back-to-mobile-1st', function () {
        ////console.log('js back to mobile 1st');
        var $this = $(this);
        if ($this.closest('.SumoSelect').length > 0 && $window.outerWidth() <= $mobile_width) {
            var $thisSelect = $this.closest('.SumoSelect').find('select')[0];
            $thisSelect.sumo.hideOpts();
            $thisSelect.sumo.unSelectAll();
        }
        closeMobile2nd();
        return false;
    });

    // Check if .mobile-block-wrap contains .mobile-1st-wrap
    if ($('.mobile-block-wrap').length > 0) {
        $('.mobile-block-wrap').each(function () {
            var $this = $(this);
            if ($this.find('.mobile-1st-wrap').length > 0) {
                $this.addClass('contains-mobile-1st-wrap');
            }
        });
    }

    // Position map in sell-list section
    function sellListMapDimensions() {
        if ($('.sell-list-map-section').length > 0) {
            var $mapSection = $('.sell-list-map-section');
            var $mapSectionInner = $('.sell-list-map-section>.inner');
            var $map = $('.sell-list-map-section').find('.map');
            var $filterAndMapWrap = $mapSection.closest('.sell-list-section').find('.filter-and-map-wrap');
            //var $windowHeight = $window.outerHeight();

            $map.css({
                'padding-top' : '',
                'height' : ''
            });

            var $availableHeight = parseFloat($mapSectionInner.outerHeight() - parseFloat($mapSectionInner.css('padding-top')) - parseFloat($mapSectionInner.css('padding-bottom')));
            var $calcHeight = parseFloat($map.outerWidth()) * 0.563;

            if( $availableHeight < $calcHeight ) {
                var $setHeight = $availableHeight;
            } else {
                var $setHeight = $calcHeight;
            }

            ////console.log('$availableHeight = '+$availableHeight+' $calcHeight = '+$calcHeight+' $setHeight = '+$setHeight);

            if ($window.outerWidth() <= $tablet_width && $window.outerWidth() > $mobile_width) {
                /*var $sellInMapWrap = $mapSection.closest('.sell-list-section').find('.sell-in-map-wrap');
                var $topPosition = parseFloat($sellInMapWrap.offset().top + $sellInMapWrap.outerHeight() - $window.scrollTop());*/
                $mapSection.css({
                    /*'top': $topPosition,
                    'height': parseFloat($windowHeight - $topPosition)*/
                    'top': 0,
                    'height': '100%',
                });
                $map.css({
                    'padding-top' : '',
                    'height' : $setHeight
                });
            } else {
                /*var $topPosition = parseFloat($filterAndMapWrap.offset().top + $filterAndMapWrap.outerHeight() - $window.scrollTop());
                var $height = parseFloat($windowHeight - $topPosition);*/

                $mapSection.css({
                    /*'top': $topPosition,
                    'height': $height*/
                    'top': 0,
                    'height': '100%',
                });
                $map.css({
                    'padding-top' : '',
                    'height' : $setHeight
                });

                if ($window.outerWidth() <= $mobile_width) {
                    $map.css({
                        'padding-top' : '',
                        'height' : '100%'
                    });
                }
            }
        }
    }

    $window.resize(function () {
        sellListMapDimensions();
    });

    // Delete block
    function deleteAddedImgBlock() {
        var $this = $(this);
        $this.closest('.added-img-block').detach();
    }

    $body.on('click', '.js-delete-block', deleteAddedImgBlock);

    // Delete target element
    function deleteEl() {
        var $this = $(this);
        var $target = $this.data('delete-target');
        $('.js-delete-el-target[data-delete-target="' + $target + '"]').detach();
        if ($this.closest('.popup-wrap').length > 0) {
            lightcase.close();
        }
        return false;
    }

    $body.on('click', '.js-delete-el', deleteEl);

    // Add input wrap
    function addInputWrap() {
        var $this = $(this);
        var $inputGroup = $this.closest('.input-group');
        var $inputWrap = $inputGroup.find('.input-wrap').first();
        if ($inputWrap.find('input[type="tel"]').length > 0) {
            $('<div class="input-wrap"><input class="input js-phone" type="tel"></div>').addClass('added-with-js').insertBefore($this);
            phoneInputs();
        } else {
            $inputWrap.clone().addClass('added-with-js').insertBefore($this).find('input').val('').removeAttr('id');
        }

        return false;
    }

    $body.on('click', '.js-add-input-wrap', addInputWrap);

    // reset window width for resize screen width checking
    $window.resize(function() {
        setTimeout(function() {
            $windowWidthResize = $window.outerWidth();
        }, 50);
    });

    $('.sell-view-top-table').each(function() {
        $(this).addClass('has-td-' + $(this).find('.td').length);
    });
});
