// Vendor resources (normalize.css, jquery and plugins by your choice)
require('./vendor/modernizr-3.6.0-custom.min.js');
require('./vendor/cookieconsent.min.js');

require("./vendor/normalize.min.css");
window.$ = window.jQuery = require("./vendor/jquery-3.3.1.min");
require("./vendor/lightslider.js");
window.sumo = require("./vendor/jquery.sumoselect.min.js");
require("./vendor/jquery.nicescroll.min.js");
window.noUiSlider = require("./vendor/nouislider.min.js");
window.MarkerWithLabel = require("./vendor/markerwithlabel.js");
require("./vendor/intlTelInput.min.js");
require("./vendor/lightcase.js");
require("./vendor/jquery-ui.min.js");
window.ResizeSensor = require("./vendor/ResizeSensor.js");

require("./vendor/lightslider.css");
require("./vendor/sumoselect.min.css");
require("./vendor/nouislider.min.css");
require("./vendor/intlTelInput.min.css");
require("./vendor/lightcase.css");

// Your own CSS files
require("./scss/style.scss");

// Your own javascript files
require("./js/app.js");
